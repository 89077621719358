<template>
    <div>
        <b-card :id="'commentId='+item.id" v-for="item in $comments.comments" :key="item.id" class="card">
            <b-media>
                <b-row class="item">
                    <b-col cols="12" class="user_head ">
                        <div class="head_userpic">
                            <div class="comments_icon userpic"
                                 :style="'background-image: url('+$comments.users[item.user_id].photo_250+')'"></div>
                        </div>
                        <p class="comments_name_header">
                            <span class="comments_name">{{$comments.users[item.user_id].name}} {{$comments.users[item.user_id].f_name}}</span>
                        </p>
                        <p class="comments_name_info">
                            <span class="comments_comment_date"
                                  v-html="$moment(item.created_at).local().format('DD.MM.YYYY в HH:mm')"/>
                        </p>
                        <b-button class="del_comment_btn" v-if="$user && $user.id && item.user_id == $user.id"
                                  @click="del(item.id)">
                            <font-awesome-icon :icon="['fa', 'times']" class="news_date_icon"/>
                        </b-button>
                    </b-col>
                    <b-col cols="12">
                        <p class="comments_comment mb-3" v-html="$htmContext(item.comment)"/>
                        <imageLoader v-if="item.image" class="d-flex float-left" :src="item.image"/>
                    </b-col>

                </b-row>
            </b-media>
        </b-card>
    </div>
</template>
<script>
    import imageLoader from "../ImageLoader/index";

    export default {
        props: {
            url: String,
        },
        components: {
            imageLoader
        },
        name: "CommentCard",
        methods: {
            del(id) {
                let form = new FormData();
                form.set('comment_id', id);
                form.set('hide_comment', '1');
                this.$http.post(this.url, form).then((response) => {
                    this.$comments.users = response.data.response.users;
                    this.$comments.comments = response.data.response.comments;
                })
            },

        }
    }
</script>
<style scoped>
    @media only screen and (max-width: 767px) {
        .comments_icon {
            width: 40px;
            height: 40px;
        }

        .comments_name_header {
            font-size: 14px;

        }
    }

    @media only screen and (min-width: 576px) {
        .item {
            padding: 0 16px 16px 16px;
        }
    }

    @media only screen and (min-width: 767px) {
        .comments_icon {
            width: 50px;
            height: 50px;
        }

        .comments_name_header {
            font-size: 18px;

        }
    }

    .card {
        border-radius: 12px;
        margin-top: 24px;
        overflow: hidden;
    }

    .card-body {
        padding: 8px;
    }

    .card-body p {
        margin-bottom: 0px;
    }

    .card-body {
        padding: 8px;
    }

    .user_head {
        text-align: left;
        position: relative;
        z-index: 1;
        min-height: 77px;
        padding: 16px 18px 0 70px;

    }

    .head_userpic {
        position: absolute;
        top: 16px;
        left: 16px;
        text-decoration: none;
        font: 0/0 a;
    }

    .comments_icon {
        border-radius: 100%;
        position: relative;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        border: none;
        background-color: #fff;
        background-position: 50%;
        background-size: cover;
        font-size: 0;

    }

    .comments_icon:before {
        box-shadow: none;

    }

    .userpic:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, .5);
        border-radius: 100%;
    }

    .comments_name_header {
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
        margin-right: 70px;
        line-height: 20px;
        margin-left: 7px;
    }

    .comments_name {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        max-width: 100%;
        font-weight: 500;
    }

    .comments_name_info {
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        flex-wrap: wrap;
        color: #939cb0;
        margin-right: 70px;
        font-size: 14px;
        line-height: 20px;
    }

    .comments_comment_date {

        font-size: 11px;
        color: #5d5d5f;
    }

    .comments_comments {
        float: right;
        color: #2c3e50;
        text-decoration: none;
        align-items: center;
    }

    .comments_comments:hover {
        color: #4a76a8;
    }

    .del_comment_btn {
        position: absolute;
        right: 16px;
        top: 16px;
        text-decoration: none;
        color: #2c3e50;
        background: transparent;
        border: none;
    }

    .del_comment_btn:hover {
        color: red;
        background: transparent;
        border: none;
    }

    .del_comment_btn:active {
        color: red;
        background: transparent;
        border-color: red;
    }

    .del_comment_btn:focus {
        color: red;
        background: transparent;

    }

    .del_comment_btn:not(:disabled):not(.disabled):active {
        color: red;
        background: transparent;
        border-color: red;
    }

    .comments_img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 12px;

    }

    .comments_comment {
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        word-break: break-word;
    }
</style>
