<template>
    <div>
        <div class="default_box" v-if="preloader">
            <Title v-if="$route.name === 'Login'"/>
            <div class="default_box_content bg_white p15">
                <div v-if="error" class="error_box" v-html="error"></div>
                <form @submit.prevent="api" autocomplete="off">
                    <div class="reg_span"><b>Войдите, чтобы управлять отзывами, комментариями и избранным на всех устройствах</b></div>
                    <label for="email" class="main_input_label">E-mail</label>
                    <input id="email" type="email" autocomplete="off" class="main_input" v-model="form.email" minlength="4" required>
                    <label for="password" class="main_input_label">Пароль</label>
                    <input id="password" type="password" autocomplete="off" class="main_input" v-model="form.password" minlength="4" required>
                    <button class="button button_default" type="submit">Войти</button>
                </form>
                <div class="reg_link_box">
                    <router-link :to="{name: 'PasswordReset'}">
                        <span>Забыли пароль? </span>
                    </router-link>
                    <router-link class="registration" :to="{name: 'UserRegistration',params: { city:$route.params.city, params: {route: this.route} }}">
                        <span class="menu_item">Регистрация</span>
                    </router-link>
                </div>
                <div class="reg_span"><b>Или авторизуйтесь через социальные сети</b></div>
                <div class="button_reg_box">
                    <button type="button" class="reb_btn vk_icon_color" @click="login_Vk">
                        <div class="icons vk_icon"></div>ВКонтакте
                    </button>
<!--                    <button type="button" class="reb_btn ok_icon_color" @click="login_OK">-->
<!--                        <div class="icons odnoklassniki_icon"></div>Одноклассники-->
<!--                    </button>-->
                    <!-- <button type="button" class="reb_btn fb_icon_color" @click="login_FB">
                        <div class="icons facebook_icon"></div>Facebook
                    </button> -->
                </div>
            </div>
        </div>
            <div v-else>
                <PreLoadBox />
            </div>
    </div>
</template>
<script>
import Title from './Title';
export default {
    name: "Login",
    components: {
        Title
    },
    props: {
        route: String
    },
    data() {
        return {
            preloader: 'finish',
            error: false,
            form: {
                email: null,
                password: null
            },
        }
    },
    methods: {
        api() {
            this.preloader = null;
            let url = this.$config.api_url + 'User.emailLogin';
            let form = new FormData();
            form.set('email', this.form.email);
            form.set('pass', this.form.password);
            this.$http.post(url, form).then((response) => {
                this.$user = response.data.response.item;
                if (this.route) {
                    if(this.route !== this.$route.fullPath) {
                        this.$router.push({ path: this.route });
                    }
                } else {
                    this.$router.push({ name: 'Home' });
                }
            }).catch((error) => {
                this.error = error.response.data.response.message;
                this.$user = null;

            }).finally(() => {
                this.preloader = 'finish';
            })
        },
        login_Vk() {
            // eslint-disable-next-line no-undef
            VK.Auth.login(this.callbackLoginVk, 4194304);

        },
        login_FB() {
            var me = this;
            // eslint-disable-next-line no-undef
            FB.login(function(response) {
                if (response.authResponse) {
                    let form = new FormData();
                    form.set('accessToken', response.authResponse.accessToken);
                    let url = me.$config.api_url + 'User.fbLogin';
                    me.$http.post(url, form).then((response) => {
                        me.$user = response.data;
                        if (me.route) {
                            if(me.route !== me.$route.fullPath) {
                                me.$router.push({ path: me.route });
                            }
                        } else {
                            me.$router.push({ name: 'Home' });
                        }
                    }).catch(() => {
                        me.$user = null;
                    });
                } else {
                    // eslint-disable-next-line no-console
                    console.log('User cancelled login or did not fully authorize.');
                }
            });
        },
        login_OK() {
            window.location.replace("https://connect.ok.ru/oauth/authorize?client_id=1262050560&redirect_uri=" + (this.route ? this.$config.url + this.route : this.$config.url) + '/login/ok' + "&scope=VALUABLE_ACCESS;LONG_ACCESS_TOKEN&response_type=token", '_blank');
        },
        // eslint-disable-next-line no-unused-vars
        callbackLoginVk(callback) {

            if (callback.status === 'connected') {
                let url = this.$config.api_url + 'Login.vk';
                this.$http.post(url, callback).then((response) => {

                    this.$user = response.data.response;
                    // this.$router.push({name: 'home'});
                    if (this.route) {
                        if(this.route !== this.$route.fullPath) {
                            this.$router.push({ path: this.route });
                        }
                    } else {
                        this.$router.push({ name: 'Home' });
                    }
                }).catch(() => {
                    this.$user = null;
                })
            } else {
                // eslint-disable-next-line no-console
                console.log('status: ' + callback.status);
            }
        }
    },
    mounted() {
        if (this.$user) {
            this.$router.push({ name: 'home' });
        }
        //VK
        this.$loadScript('https://vk.com/js/api/openapi.js?168').then(function() {
            // eslint-disable-next-line no-undef
            VK.init({
                apiId: 6323130
            });
        });
        //FB
        window.fbAsyncInit = function() {
            // eslint-disable-next-line no-undef
            FB.init({
                appId: '147032306091326',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v7.0'
            });
        };
        this.$loadScript('https://connect.facebook.net/ru_RU/sdk.js').then(function() {

        });
    }
}
</script>
<style scoped>
@media only screen and (max-width: 767px) {
    .button_reg_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .button_reg_box button {
        margin-bottom: 20px;
        width: 100%;
    }

    .reg_span {
        font-size: 14px;
    }

}

@media (min-width: 768px) {
    .button_reg_box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 20px;
    }
}

.reb_btn {
    padding: 10px 20px;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    transition: .3s;
}

.reb_btn:hover {
    transform: translateY(-5px);
    transition: .3s;
}

.icons {
    width: 30px;
    height: 30px;
    background-color: #A9B0C0;
    display: block;
    margin-right: 10px;

}

.reg_span {
    padding: 20px 0;
}

.fb_icon_color {
    background-color: #4267B2;

}

.vk_icon_color {
    background-color: #4a76a8;
}

.ok_icon_color {
    background-color: #ee8208;
}

.vk_icon {
    mask-image: url('/images/icon/new_icon/vk.svg');
    background-color: #fff;
}

.odnoklassniki_icon {
    mask-image: url('/images/icon/new_icon/odnoklassniki.svg');
    background-color: #fff;
}

.facebook_icon {
    mask-image: url('/images/icon/new_icon/facebook.svg');
    background-color: #fff;
}

.reg_link_box {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reg_link_box a {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
}

.error_box {
    background: #b71c1c;
    color: #fff;
    border-radius: 12px;
    padding: 15px;
}
</style>