<template>
    <div>
        <div v-if="src" class="">
            <slot>
                <img :src="src" class="image" :style="height ? 'max-height:' +height+'px' : 'max-height:500px'" alt="Картинка" v-on:click="open()" />
            </slot>
        </div>
        <div v-if="list" class="">
            <carousel :per-page="1" :mouse-drag="false" :navigationEnabled="true" paginationPosition="bottom">
                <slide v-for="imageItem in list" :key="imageItem.image">
                    <img v-if="imageItem.image" :src="imageItem.image" v-on:click="openCarusel(imageItem.image)" alt="Картинка" class="image" style="max-height: 500px">
                    <img v-else-if="imageItem" :src="imageItem" v-on:click="openCarusel(imageItem.image)" alt="Картинка" class="image" style="max-height: 500px">
                </slide>
            </carousel>
        </div>
        <div v-if="Visibility">
            <div class="full" v-on:click="close()">
                <div class="full_item"></div>
                
            </div>
            <div class="image_box">
                <img v-if="src" class="full_img" :src="src" alt="Картинка" v-on:click="open()" />
            </div>
            <div v-if="list" class="image_boxes">
                <carousel :per-page="1" :mouse-drag="false" :navigationEnabled="true" paginationPosition="bottom">
                    <slide v-for="imageItem in list" :key="imageItem.image" >
                        <img v-if="imageItem.image" class="img_car" alt="Картинка" :src="imageItem.image">
                        <img v-else-if="imageItem" class="img_car" alt="Картинка" :src="imageItem">
                    </slide>
                </carousel>
            </div>
            <div class="close_img" v-on:click="close()">X</div>
        </div>
    </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
    name: 'imageLoader',
    components: {
        Carousel,
        Slide
    },
    props: {
        src: String,
        list: Array,
        height: Number
    },
    data() {
        return {
            Visibility: false,
            link: null,
        }
    },
    methods: {
        prev() {

        },
        close() {
            this.Visibility = false;
            document.querySelector('html').style.overflowY = 'scroll';
        },
        open() {
            this.Visibility = true;
            document.querySelector('html').style.overflow = 'hidden';
        },
        openCarusel(elem) {
            document.querySelector('html').style.overflow = 'hidden';
            this.Visibility = true;
            this.link = elem;
        },

    },


}
</script>
<style scoped>
@media only screen and (max-width: 767px) {
    .image_box {
        width: 100%;
        max-height: 100%;
    }
    .full_img {
        max-width: 100%;
        max-height: 100%;
    }
    
    

    .close_img {
        height: 40px;
        width: 40px;
        top: 15px;

    }
    .image_boxes {
        width: 100%;
        max-height: 100%;
    }

    .img_car {
        max-width: 100%;
        max-height: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .full_img {
        max-height: 80%;
    }
    
    .image_boxes {
        max-height: 80%;
    }

    .close_img {
        height: 60px;
        width: 60px;
        font-size: 30px;
        top: 72px;

    }
}

.full {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(74, 118, 168, 0.75);
    z-index: 9999;
    backdrop-filter: blur(20px);
    display: block;
    height: 100%;
    width: 100%;
}


.full_img {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 9999;
}

.image_boxes {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 9999;
}
.image {
    border-radius: 12px;
    
    width: auto;
    cursor: pointer;
}

.VueCarousel {
    margin: 0;
}
.VueCarousel-inner {
    height: 500px !important;
}

.VueCarousel-slide {
    align-items: center !important;

}

.VueCarousel-pagination {
    margin: 0;
    padding: 0;
}



.example-slide {
    align-items: center;
    background-color: #666;
    color: #999;
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    min-height: 10rem;
}

.close_img {
    position: fixed;
    
    right: 15px;
    background: white;
    z-index: 10000 !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4a76a8;
    font-weight: bold;
    transition: .5s;
    cursor: pointer;
}

.close_img:hover {
    background: #4a76a8;
    color: #fff;
    border: 3px solid #fff;
}


</style>