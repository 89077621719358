/* eslint-disable no-console */

import Vue from "vue";

let comments = new Vue({
    data: {
        comments: [],
        users: {}
    }
});

Vue.mixin({
    computed: {
        $comments: {
            get: function () {
                return comments.$data
            },
            set: function (s) {
                comments.$data = s;
            },
        }
    }
});
