<template>
    <div v-if="preLoad">
        <form class="comment_box" @submit.prevent="send">
            <div class="comment_file_button_box">
                <b-form-file id="fileUpload" v-model="form.file" placeholder="" drop-placeholder="" accept="image/jpeg, image/png" plain class="file-upload" hidden></b-form-file>
                <label for="fileUpload" class="btn_file">
                    <div class="comment_file_icon"></div>
                </label>
            </div>
            <textarea v-$autogrow type="text" v-model.trim="form.text" maxlength="2000"></textarea>
            <button type="submit" class="comment_button_send" :disabled="(!form.text.length && !form.file) || button.disabled">
                <div class="comment_send_icon" :class="form.text.length ? 'active' : ''"></div>
            </button>
        </form>
        <small class="comment_text_length">{{ form.text.length.toLocaleString() }} / 2 000</small>
        <div v-if="img" class="img_preloader">
            <div class="img_preloader_item">
                <img :src="img" alt="Предзагрузка фото" class="img-fluid">
                <button @click="form.file = null, img = null" class="comment_image_remove" title="Отправить">
                    <div>+</div>
                </button>
            </div>
        </div>
    </div>
    <div v-else>
        <PreLoadBox />
    </div>
</template>
<script>
export default {
    name: "CommentsForm",
    props: {
        url: String,
        commentsId: Number,
    },

    data() {
        return {
            preLoad: 'finish',
            form: {
                file: null,
                text: '',
            },
            img: null,
            button: {
                disabled: false,
                text: (!this.commentsId) ? 'Send' : 'Update',
            },
        }
    },
    methods: {
        send() {
            if (!this.form.file && !this.form.text.trim()) {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: 'Выберите изображение или введите комментарий',
                    title: 'Ошибка!',
                    duration: 5000,
                });
            } else {
                this.preLoad = null;
                let form = new FormData();
                form.set('comment', this.form.text);
                if (this.form.file) form.set('pic', this.form.file);
                if (this.commentsId) form.set('comment_id', this.commentsId);
                this.button.disabled = true;
                this.button.text = (!this.commentsId) ? 'Sending' : 'Updated';
                this.$http.post(this.url, form).then((response) => {
                    this.formReset();
                    this.$comments.comments = response.data.response.comments;
                    this.$comments.users = response.data.response.users;
                }).finally(() => {
                    this.button.disabled = false;
                    this.button.text = (!this.commentsId) ? 'Send' : 'Update';
                    this.preLoad = 'finish';
                });
            }

        },
        formReset() {
            this.form.file = null;
            this.img = null;
            this.form.text = '';
        },
    },
    watch: {
        'form.file': function() {
            if (this.form.file) {
                let image = new Image();
                image.src = URL.createObjectURL(this.form.file);
                image.onload = () => {
                    if (image.width > 149 && image.height > 149) {
                        this.img = URL.createObjectURL(this.form.file);
                    } else {
                        this.form.file = null;
                        alert('Минимальный размер картинки 150х150 пикселей');
                    }

                }
            }
        },
    },
}
</script>
<style scoped>
.comment_box {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.comment_text_length {
    color: #919191;
    margin: 10px 0 0;
    display: block;
    text-align: right;
    background: #fff;
}

.comment_box textarea {
    padding: 10px 15px;
    line-height: 24px;
    word-wrap: break-word;
    resize: none;
    font-size: 14px;
    text-align: left;
    width: 100%;
    border-radius: 4px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 400px;
    transition: .2s;
    height: auto;
}

.comment_box textarea::-moz-scrollbar {
    width: 4px;
    height: 4px;
}

.comment_box textarea::-moz-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.comment_box textarea::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}


.comment_box textarea::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.comment_button_send {
    background: transparent;
}

.comment_send_icon {
    background-color: #919191;
    width: 30px;
    height: 30px;
    mask-image: url('/images/icon/sendIcon.svg');
    mask-repeat: no-repeat;
    transition: .2s;
}

.comment_send_icon.active {
    background: rgba(var(--main-color), 1.0);
}

.comment_file_button_box {
    position: relative;
}

.comment_file_button_box button {
    background: transparent;
}

.comment_file_icon {
    background-color: #919191;
    width: 30px;
    height: 30px;
    mask-image: url('/images/icon/paperclipIcon.svg');
    mask-repeat: no-repeat;
    transition: .2s;
    cursor: pointer;
}

.comment_file_button_box input[type=file] {
    /*font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;*/
    display: none;
}

.img_preloader {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    position: relative;
}

.img_preloader_item {
    position: relative;
}

.img_preloader img {
    max-height: 150px;
    border-radius: 4px;
    border: 1px dashed rgba(0, 0, 0, 0.1);
}

.comment_image_remove {
    background: rgba(29, 32, 34, .6);
    padding: 5px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: .2s;
}

.comment_image_remove div {
    color: #fff;
    font-size: 32px;
    transform: rotate(45deg);
    font-weight: bold;
}

.comment_image_remove:hover {
    background: rgba(29, 32, 34, 1);
    transition: .2s;
    cursor: pointer;
}
</style>