<template>
    <div>
        <div class="comment_card">
            <h5 class="comment_title">{{title?title:'Комментарии'}}</h5>
            <div v-if="$user">
                <CommentsForm :url="url" />
            </div>
            <div v-else>
                <UserLogin :route="$route.fullPath" />
            </div>
        </div>
        <CommentCard :url="url" />
    </div>
</template>
<script>
/* eslint-disable */
import './model'

import UserLogin from '@/components/App/Login/Login';;
import CommentCard from "./Card";
import CommentsForm from "./newForm";

export default {
    name: "Comments",
    components: { CommentsForm, CommentCard, UserLogin },
    props: {
        url: String,
        comments: Array,
        users: Object,
        title: String
    },
    data() {
        return {
            form: {
                file: null,
                text: '',
            },
            img: null,
            button: {
                disabled: false,
                text: 'Send',
            },
        }
    },
    methods: {
        send() {
            let form = new FormData();
            form.set('comment', this.form.text);
            if (this.form.file) form.set('pic', this.form.file);
            this.button.disabled = true;
            this.button.text = 'Sending';
            this.$http.post(this.url, form).then((response) => {
                this.formReset();
                this.$comments.comments = response.data.response.comments;
                this.$comments.users = response.data.response.users;
            }).finally(() => {
                this.button.disabled = false;
                this.button.text = 'Send';
            });
        },
        formReset() {
            this.form.file = null;
            this.form.text = '';
        },
    },

    mounted() {
        this.$comments.comments = this.comments;
        this.$comments.users = this.users;
    },
    watch: {
        'comments': function() {
            this.$comments.comments = this.comments;
        },
        'users': function() {
            this.$comments.users = this.users;
        },
    },
    destroyed() {
        this.$comments.comments = [];
        this.$comments.users = {}
    }
}
</script>
<style scoped>
@media only screen and (max-width: 450px) {
    .login {
        font-size: 12px;
    }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
    .login {
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) {
    .login {
        font-size: 16px;
    }
}

.comment_title {
    padding: 5px;
    margin: 0 0 10px 0px;
    font-weight: bold;
}

.card {
    border-radius: 12px;
    margin-bottom: 24px;
}

.login {
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.5rem;
    margin-bottom: 10px;
}

.login a {
    text-decoration: none;
}

.comment_card {
    border-radius: 12px;
    background: #fff;
    padding: 15px;
}
</style>